@import "../../styles/config.scss";

.hidden{
    visibility: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $secondary-1;
    box-sizing: border-box;
    padding: 20px 25px;

    &__shadow-in {
        transition: box-shadow .5s ease 0s;
        -webkit-box-shadow: 0px 4px 4px -4px $primary-1;
        -moz-box-shadow: 0px 4px 4px -4px $primary-1;
        box-shadow: 0px 4px 4px -4px $primary-1;
    }
    &__shadow-out {
        transition: box-shadow .5s ease 0s;
        -webkit-box-shadow: 0 0 0 0transparent;
        -moz-box-shadow: 0 0 0 0 transparent;
        box-shadow: 0 0 0 0transparent;
    }

    &__logo {
        display: flex;
        align-items: center;
        &:focus-visible {
            @include focus-border();
        }

        &-img {
            padding: 0 .5em;
            height: 35px;
            width: auto;
        }
    }
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 1650px;
}

.skip-link {
    @include visually-hidden;

    &:focus-visible {
        clip: auto;
        clip-path: none;
        height: auto;
        width: auto;
        outline-offset: -2px;
        top: -4px;
        left: 50%;
        transform: translateX(-50%);
        text-decoration: none;
        background-color: $secondary-1;
        padding: 0.2rem 0.4rem;
        @include focus-border();
    }
}


.navigation {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 54%;
}


.bracket-hover a::before,
.bracket-hover a::after {
    display: inline-block;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
    -moz-transition: -moz-transform 0.3s, opacity 0.2s;
    transition: transform 0.3s, opacity 0.2s;
}

.bracket-hover a::before {
    margin-right: 3px;
    content: '<';
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    transform: translateX(20px);
}

.bracket-hover a::after {
    margin-left: 3px;
    content: '/>';
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    transform: translateX(-20px);
}

.bracket-hover a:hover::before,
.bracket-hover a:hover::after,
.bracket-hover a:focus::before,
.bracket-hover a:focus::after {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
}

.bracket-hover a:focus-visible {
    @include focus-border();
}

@media (min-width: 1000px) {
    .navigation {
        width: 48%;
    }
}

@media (max-width: 670px) {
    .navigation {
        width: 65%;
    }
}


@media (max-width: 550px) {
    .header {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 0.8em;
        padding: 15px 20px;
    }

    .navigation {
        width: 100%;
        padding-top: 8px;
        padding-right: 45px;
        padding-left: 45px;
    }

    .hidden{
        display: none;
    }
}
