@import "../../styles/config.scss";

.contact {
    // margin-top: 65px;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__body {
        padding-left: 35px;
        width: 50%;

        &-title {
            @include h1();
            margin-bottom: 15px;
        }

        &-subtitle {
            @include p-alt();
        }

        &-resume {
            border-radius: 0;
            text-decoration: none;
            color: #36363C;
            display: inline-block;
            padding: 0px 5px;
            transition: all .2s ease-in-out;
            box-sizing: border-box;
            text-decoration: none;
            box-shadow: inset 0 -5px 0 $primary-3;

            &:hover {
                box-shadow: inset 0 -55px 0 0 $primary-3;
                color: white;
            }

            &:focus, &:focus-visible {
                @include focus-border();
            }
        }
        &-links{
            display: none;        
        }
    }

    &__img {
        &-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 50%;
            overflow: hidden;
            padding-right: 24px;

            &-small,
            &-medium {
                display: none;
            }

            &-large {
                display: flex;
            }
        }

        &-link {
            cursor: pointer;

            g {
                transition: opacity .2s ease 0s;
                opacity: 0;
            }

            g:hover, g:focus, g:focus-visible{
                transition: opacity .2s ease 0s;
                z-index: 1;
                opacity: 1;
            }
        }

        width: 100%;
        height: auto;
        max-width: 653px;
    }
}

@media (max-width: 1372px) {
    .contact {
        &__img-wrapper {
            width: 77%;
            align-self: flex-end;
        }

        &__body {
            width: 33%;
        }
    }
}

@media (max-width: 1000px) {
    .contact {
        &__img-wrapper {
            height: auto;
            max-width: 501px;
            align-self: flex-end;
            &-small,
            &-large {
                display: none;
            }

            &-medium {
                display: flex;
            }
        }
    }
}


@media (max-width: 750px) {
    .contact {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        &__body {
            width: unset;
            padding: 35px 35px 0px 35px;
            margin-top: 10px;

            &-paragraph {
                max-width: 100%;
            }
        }

        &__img {
            &-wrapper {
                width: 100%;
                margin: -10px auto 0;
            }
        }
    }
}


@media (max-width: 550px) {
    .contact__body{
        padding-top: 85px;

        &-links{
            display: flex;
            list-style-type: none;
            flex-wrap: wrap;
            padding-top: 20px;
            justify-content: space-between;
        }
        &-link{
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border: 1px solid transparent;

            &-logo{
                display: flex;
            }
            &-hover{
                display: none;
            }

            &:hover > &-logo{
                display: none;
            }

            &:hover > &-hover{
                display: flex;
            }

            &:focus{
                border: 1px solid $primary-3;
            }
            img{
                width: 30px;
                height: auto;
            }
        }
    }
    .contact__img {
        &-wrapper {
            width: 100%;
            margin-top: -10px;
            padding-right: 0;
            &-large,
            &-medium {
                display: none;
            }

            &-small {
                display: flex;
            }
        }
    }
}


@media (max-width: 550px) {
    .contact__body{
        &-links{
            display: flex;
            list-style-type: none;
            flex-wrap: wrap;
            padding-top: 20px;
            padding-right: 20px;
            justify-content: space-between;
        }
        &-link{
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border: 1px solid transparent;

            &-logo{
                display: flex;
            }
            &-hover{
                display: none;
            }

            &:hover > &-logo{
                display: none;
            }

            &:hover > &-hover{
                display: flex;
            }

            &:focus{
                border: 1px solid $primary-3;
            }
            img{
                width: 30px;
                height: auto;
            }
        }
    }
    .contact__img {
        &-wrapper {
            width: 100%;
            margin-top: -10px;
            padding-right: 0;
            &-large,
            &-medium {
                display: none;
            }

            &-small {
                display: flex;
            }
        }
    }
}


@media (max-width: 550px) {
    .contact__body{
        &-links{
            display: flex;
            list-style-type: none;
            flex-wrap: wrap;
            padding-top: 20px;
            padding-right: 20px;
            justify-content: space-between;
        }
        &-link{
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border: 1px solid transparent;

            &-logo{
                display: flex;
            }
            &-hover{
                display: none;
            }

            &:hover > &-logo{
                display: none;
            }

            &:hover > &-hover{
                display: flex;
            }

            &:focus{
                border: 1px solid $primary-3;
            }
            img{
                width: 30px;
                height: auto;
            }
        }
    }
    .contact__img {
        &-wrapper {
            width: 100%;
            margin-top: -10px;
            padding-right: 0;
            &-large,
            &-medium {
                display: none;
            }

            &-small {
                display: flex;
            }
        }
    }
}