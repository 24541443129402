@import './normalize.scss';
@import './skeleton.scss';
@import './colours.scss';
@import './typography.scss';


@mixin visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;
}

@mixin focus-border {
    border-color: $primary-3;
    outline: none;
    box-shadow: 0 0 0 1px $primary-3;
}


html {
    @include body-text;
    color: $primary-1;
}


h1 {
    @include h1;
}

h2 {
    @include h2;
}

h3 {
    @include h3;
}

h4 {
    @include h4;
}

h5 {
    @include h5;
}


a {
    color: $primary-3;
    text-decoration: none;
    &:hover,
    &:active,
    &:visited:hover {
        color: $primary-1;
    }
    &:visited {
        color: $primary-3;
    }

    &:focus-visible{
        @include focus-border();
    }
}