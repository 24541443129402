@import "../../styles/config.scss";

.about {
    // margin-top: 65px;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__body {
        padding-right: 35px;
        width: 55%;

        &-title {
            @include h1();
            margin-bottom: 20px;
        }

        &-paragraph {
            @include p-alt();
        }
    }

    &__img {
        &-wrapper {
            width: 55%;
            overflow: hidden;
        }

        height: auto;
        width: 100%;
        max-width: 653px;
    }
}


@media (max-width: 750px) {
    .about {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;

        &__body {
            width: unset;
            padding: 35px 35px 0px 35px;
            margin-top: 10px;

            &-title {
               padding-top: 55px;
            }
    

            &-paragraph {
                max-width: 100%;
                font-size: 12px;
                margin-bottom: 5px;
            }
        }

        &__img {
            &-wrapper {
                width: 100%;
                margin-top: -10px;
                // display: flex;
                // justify-content: center;
            }

            height: auto;
            width: 100%;
            // max-width: 80%;
        }
    }
}