$ecru-white: #F7F5EC;
$parchment: #F1E5D7;
$cameo: #D6B195;
$prairie-sand: #92331E;
$prairie-sand-light: #C24529;
$old-gold: #CF9942;
$regent-gray: #79929E;
$outer-space: #2B363D;

$primary-1: $outer-space;
$primary-2: $old-gold;
$primary-3: $prairie-sand;

$secondary-1: $ecru-white;
$secondary-2: $parchment;
$secondary-3: $cameo;

$tertiary-1: $regent-gray;
$tertiary-2: $prairie-sand-light;

